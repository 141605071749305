<template>
    <v-card
        :href="ads_data.link_url"
        target="_blank"
        class="mb-2"
    >
        <v-img :src="ads_data.image_url" max-height="163px" />

        <v-card-title v-if="ads_data.name">
            {{ ads_data.name | truncate }}
        </v-card-title>
    </v-card>
</template>

<script>
export default {
    props: ['ads_data'],

    filters: {
        truncate: function (text, length, suffix) {
            if (text.length > length) {
                return text.substring(0, length) + suffix;
            } else {
                return text;
            }
        },
    }
}
</script>

<style scoped lang="scss">
.text--black {
    color: black;
}


.fz-24 {
    font-size: 24px;
    @media all and (max-width: 1240px) {
        font-size: 18px;
    }

    @media all and (max-width: 900px) {
        font-size: 24px;
    }
}

.fz-16 {
    font-size: 16px;
    line-height: 20px;

    @media all and (max-width: 1240px) {
        font-size: 14px;
    }

    @media all and (max-width: 900px) {
        font-size: 16px;
    }
}

.height100 {
    height: 100%;
}

.bg_white {
    background: #ffffff;
}

.min-width {
    min-width: 100%;
}

.border-radius0 {
    border-radius: 0 !important;
}
</style>
